.sve-topic-header {
  &_image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: calc(-50vw + 100vw / 2);
    margin-right: calc(-50vw + 100vw / 2);
    width: 100%;

    picture {
      width: 100%;
    }

    img {
      height: 180px;
    }
  }

  &_image-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  &_title {
    position: absolute;
    font-family: $font-primary;
    font-weight: 700;
    font-size: 45px;
    line-height: 52px;
    color: c(neutral-100);
  }

  .fp-article-list {
    width: 70%;
    margin: $spacer-sm auto $spacer-base;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    > li:not(:last-child) {
      padding-bottom: $spacer-base;
    }
  }

  &_details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $spacer-xs $spacer-base $spacer-lg $spacer-base;
    margin-bottom: $spacer-xxl;

    @include media-breakpoint-down(md) {
      margin-bottom: $spacer-base;
    }

    .list-content {
      margin: 0 8rem;

      @include media-breakpoint-down(md) {
        margin: 0 $spacer-base;
      }
    }

    .sve-btn {
      &_outline {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: $spacer-sm $spacer-xl;
        gap: $spacer-xs;
        border: 2px solid $on-background;
        border-radius: 100px;

        > span {
          font-family: $font-primary;
          font-size: $text-sm;
          line-height: 16px;
          letter-spacing: 0.5px;
          font-weight: bold;

          @include media-breakpoint-down(sm) {
            font-size: $text-xs;
          }
        }
      }
    }
  }
}
