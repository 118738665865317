@use 'sass:map';
@use 'sass:math';

// Product Typography
// @import 'font-face';

$font-primary: 'Ubuntu', sans-serif;
$font-secondary: 'merriweather', sans-serif;

$type-base-size: 1rem; // 16px

// Select size-ratio; see the following example
//**
//"minorSecond": 1.067,
//"majorSecond": 1.125,
//"minorThird": 1.2,
//"majorThird": 1.25,
//"perfectFourth": 1.333,
//"augmentedFourth": 1.414,
//"perfectFifth": 1.5,
//"goldenRatio": 1.618
$type-size-ratio: 1.125;

// List in descending order to prevent extra sort function
// (type levels name are part of the variables)
$type-levels-lg: 9, 8, 7, 6, 5, 4, 3, 2, 1;
$level-size-lg: $type-base-size;
$type-styles-lg: ();

@each $level in $type-levels-lg {
  $level-size-lg: $level-size-lg * $type-size-ratio;
  $type-styles-lg: map-merge(
    $type-styles-lg,
    (#{'ts-'}#{$level}: $level-size-lg)
  );

  // Assign to element and create utility class
  .ts-#{$level} {
    // Fallback for browsers that don't support min / max
    font-size: $level-size-lg;

    // Recommendation courtesy of this work:
    // @link https://kittygiraudel.com/2020/05/18/using-calc-to-figure-out-optimal-line-height/
    line-height: calc(2px + 2ex + 2px);

    // Set with `em` to be relative to current `font-size`
    margin-bottom: 0.65em;

    @if ($type-size-ratio > 2) {
      // remove the unit from our $level-size-lg value:
      $level-unitless: $level-size-lg / ($level-size-lg * 0 + 1);

      // Set minimum size to a percentage less than $level-size
      // Reduction is greater for large font sizes (> 4rem) to help
      // prevent overflow due to font-size on mobile devices
      // Allow a reduction of 0.5 (50%) - and smaller sizes are allowed a 0.33 (33%) reduction.
      $fluid-reduction: if($level-size-lg > 4, 0.5, 0.33);
      $fluid-min: $level-unitless - ($fluid-reduction * $level-unitless);

      // Prevent dropping lower than 1rem (body font-size)
      $fluid-min: if($fluid-min > $type-base-size, $fluid-min, 1);
      $fluid-scaler: ($level-unitless - $fluid-min) + 4vw;

      font-size: clamp(
        #{$fluid-min}rem,
        #{$fluid-scaler} + 1rem,
        #{$level-size-lg}
      );
    }
  }
}

///* Small sizes */
$type-levels-sm: '14', '12';
$level-size-sm: $type-base-size;
$type-styles-sm: ();

@each $name in $type-levels-sm {
  $level-size-sm: math.div($level-size-sm, $type-size-ratio);
  $type-styles-sm: map-merge(
    $type-styles-sm,
    (#{'ts-'}#{$name}: $level-size-sm)
  );
  .ts-#{$name} {
    font-size: $level-size-sm;
  }
}

// Merge Large and small type font-sizes
$type-styles: map-merge($type-styles-lg, $type-styles-sm);

$ts-46: map.get($type-styles, 'ts-1'); //2.887rem/46.18px
$ts-41: map.get($type-styles, 'ts-2'); //2.566rem/41.05px
$ts-36: map.get($type-styles, 'ts-3'); //2.281rem/36.49px
$ts-32: map.get($type-styles, 'ts-4'); //2.027rem/32.44px
$ts-28: map.get($type-styles, 'ts-5'); //1.802rem/28.83px
$ts-25: map.get($type-styles, 'ts-6'); //1.602rem/25.63px
$ts-22: map.get($type-styles, 'ts-7'); //1.424rem/22.78px
$ts-20: map.get($type-styles, 'ts-8'); //1.266rem/20.25px
$ts-18: map.get($type-styles, 'ts-9'); //1.125rem/18.00px
// Smaller scale
$ts-14: map.get($type-styles, 'ts-14'); //0.889rem/14.22px
$ts-12: map.get($type-styles, 'ts-12'); //0.79rem/12.64px

$text-xs: $ts-12;
$text-sm: $ts-14;
$text-base: $type-base-size;
$text-md: $ts-18;
$text-lg: $ts-20;
$text-xl: 22px;
$text-xxl: $ts-32;
$text-xxxl: $ts-46;

// Basic Typography

body {
  color: $paragraph-text-color;
  font-family: $font-primary;
  font-size: $text-base;
  line-height: normal;
}

p {
  font-family: $font-secondary;
  font-size: $text-md;
  font-weight: normal;
  line-height: 1.625;
  margin-bottom: 1.5rem;

  @media screen and (max-width: 576px) {
    font-size: $text-base;
  }
}

h1 {
  font-size: $text-xxxl;

  @media screen and (max-width: 576px) {
    font-size: 28px;
    margin-bottom: 0.5rem;
  }
}

h2 {
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 500;

  @media screen and (max-width: 576px) {
    font-size: $text-xl;
  }
}

h3 {
  font-size: $text-xl;
  margin-bottom: 0.75rem;
  font-weight: 500;

  @media screen and (max-width: 576px) {
    margin-bottom: 0.5rem;
    font-size: $text-md;
  }
}

h4 {
  font-size: $text-lg;
  font-weight: 500;
}

.label-xl {
  font-family: $font-primary;
  font-size: $text-base;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 20px;
  margin-bottom: 6px;
}
