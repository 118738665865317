//Editor Style
.editor-container {
  .placeholder_fp-free-html {
    background-color: #ebebeb;
  }

  .placeholder {
    &_fp-free-html {
      min-height: 200px;
      line-height: 200px;
      text-align: center;

      &-background {
        background-color: #ebebeb;
      }

      &-icon {
        position: relative;

        > svg {
          left: 50%;
          position: absolute;
          right: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 50%;
        }
      }
    }

    //Article Teaser Placeholder
    &_article-teaser {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &_article-teaser-img {
      background-color: lightgrey;
      height: 150px;
      width: 150px;
    }

    &_article-teaser-lines {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 16px;
      width: 100%;

      > span {
        background: #ebebeb;
        height: 18px;
        width: 100%;
      }
    }
  }
}

.placeholder_article-teaser-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 8px 0;
}

.placeholder_article-teaser-list-category {
  .placeholder_article-teaser {
    flex-direction: column;
    margin: 0 auto;
    max-width: 900px;
  }

  .placeholder_article-teaser-img {
    padding-top: 45%;
    width: 100%;
  }
}

.editor-list1,
.editor-list2,
.editor-list3,
.editor-teaser {
  align-items: center;
  background: gainsboro;
  display: flex;
  justify-content: center;
  margin: 8px;
  min-height: 308px;
}

.editor-list2,
.editor-teaser {
  min-height: 150px;
}

.editor-container {
  .fp-article-group-list-view {
    width: 100%;

    list {
      width: 100%;
    }

    ld-include {
      width: 100%;
    }
  }

  .editor-placeholder {
    background-color: #ebebeb;
    min-height: 200px;
    line-height: 200px;
    text-align: center;
  }

  .editor-teaser-small {
    min-height: 140px;
    gap: 8px;
    padding: 8px;
  }

  .editor-teaser-small_img {
    background-color: #dedede;
  }

  .editor-teaser-small_text-lines {
    background-color: #dedede;
    height: 15px;
    margin: 4px;
  }

  //  fp-article-group-large-list
  .fp-article-group-large-list {
    ld-include {
      width: 100%;
    }

    .editor-teaser-small_img {
      height: 200px;
    }

    .editor-teaser-small_text-lines {
      background-color: #dedede;
      height: 15px;
      margin: 4px;
    }
  }

  //fp-article-group-small-list
  .fp-article-group-small-list {
    ld-include {
      grid-column: 1 / span 3;
    }

    .editor-right .editor-teaser-small_img {
      height: 200px;
    }
  }

  .fp-highlighted-articles-group-list {
    ld-include {
      width: 100%;
    }

    .editor-teaser-small {
      height: 240px;
    }
  }
}
