$sve-theme-colors: (
  'primary-0': #000,
  'primary-10': #410001,
  'primary-20': #680002,
  'primary-30': #900c0d,
  'primary-40': #b32922,
  'primary-50': #d54237,
  'primary-60': #f95c4e,
  'primary-70': #ff8979,
  'primary-80': #ffb4a9,
  'primary-90': #ffdad4,
  'primary-95': #ffede9,
  'primary-99': #fcfcfc,
  'primary-100': #fff,
  'secondary-0': #000,
  'secondary-10': #3f0016,
  'secondary-20': #64042a,
  'secondary-30': #832040,
  'secondary-40': #a23857,
  'secondary-50': #c1516f,
  'secondary-60': #e26a89,
  'secondary-70': #ff85a4,
  'secondary-80': #ffb1c2,
  'secondary-90': #ffd9e0,
  'secondary-95': #ffecef,
  'secondary-99': #fcfcfc,
  'secondary-100': #fff,
  'tertiary-0': #000,
  'tertiary-10': #251a00,
  'tertiary-20': #3f2e00,
  'tertiary-30': #5a4400,
  'tertiary-40': #775a00,
  'tertiary-50': #957200,
  'tertiary-60': #b28b0d,
  'tertiary-70': #d0a62e,
  'tertiary-80': #eec148,
  'tertiary-90': #ffdf8c,
  'tertiary-95': #ffefca,
  'tertiary-99': #fffbf7,
  'tertiary-100': #fff,
  'error-0': #000,
  'error-10': #410001,
  'error-20': #680003,
  'error-30': #930006,
  'error-40': #ba1b1b,
  'error-50': #dd3730,
  'error-60': #ff5449,
  'error-70': #ff897a,
  'error-80': #ffb4a9,
  'error-90': #ffdad4,
  'error-95': #ffede9,
  'error-99': #fcfcfc,
  'error-100': #fff,
  'neutral-0': #000,
  'neutral-10': #191c1e,
  'neutral-20': #2e3133,
  'neutral-30': #454749,
  'neutral-40': #5c5e61,
  'neutral-50': #757779,
  'neutral-60': #8f9194,
  'neutral-70': #a9abad,
  'neutral-80': #c5c6c9,
  'neutral-90': #e1e2e5,
  'neutral-95': #f0f1f4,
  'neutral-99': #fbfcff,
  'neutral-100': #fff,
  'neutral-variant-0': #000,
  'neutral-variant-10': #251917,
  'neutral-variant-20': #3b2d2b,
  'neutral-variant-30': #534341,
  'neutral-variant-40': #6b5a58,
  'neutral-variant-50': #857371,
  'neutral-variant-60': #a08c89,
  'neutral-variant-70': #bca7a4,
  'neutral-variant-80': #c5c6c9,
  'neutral-variant-90': #e1e2e5,
  'neutral-variant-95': #f0f1f4,
  'neutral-variant-99': #fbfcff,
  'neutral-variant-100': #fff,
);

@function c($color-name: null) {
  @return map-get($sve-theme-colors, $color-name);
}

// Usage: eg. color: c(primary-40);

$on-primary: c(primary-100);
$on-secondary: c(secondary-100);
$on-tertiary: c(tertiary-100);
$on-error: c(error-100);

$primary-container: c(primary-90);
$on-primary-container: c(primary-10);
$secondary-container: c(secondary-90);
$on-secondary-container: c(secondary-10);
$tertiary-container: c(tertiary-90);
$on-tertiary-container: c(tertiary-10);
$error-container: c(error-90);
$on-error-container: c(error-10);

$background: c(neutral-99);
$on-background: c(neutral-10);
$surface: c(neutral-99);
$on-surface: c(neutral-10);
$surface-variant: c(neutral-variant-90);
$on-surface-variant: c(neutral-variant-30);
$outline: c(neutral-90);

$advertorial: #22abb2;
$advertorial-background: #fff;
$heimat: #4ab32b;
$heimat-background: #f2f5f1;
