%paywall-list-styles {
  .fp-article-list .list-item {
    list-style-type: none;
    font-family: $font-primary;
    font-size: $text-md;
    font-weight: 500;
    display: flex;
    color: c(neutral-40);

    @include media-breakpoint-up(sm) {
      &::before {
        content: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512" fill="%23b32922"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>');
        display: inline-block;
        margin-right: 10px;
        letter-spacing: 0.1px;
        line-height: 1.3;
        vertical-align: middle;
        padding-top: 4px;
        opacity: 1;
      }
    }

    @include media-breakpoint-down(sm) {
      font-size: $text-sm;

      &::before {
        content: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512" fill="%23b32922"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>');
        display: inline-block;
        margin-right: 10px;
        letter-spacing: 0.1px;
        line-height: 20px;
        vertical-align: middle;
        padding-top: 2px;
        opacity: 1;
      }
    }

    &:not(:last-child) {
      margin-bottom: $spacer-md;
    }
  }
}

// Editor Styles
.editor-container.paywall-container {
  .paywall_content {
    @extend %paywall-list-styles;

    .fp-article-list ul {
      margin: 0;
      padding: 0;
    }

    .fp-article-list .list-item::before {
      @include media-breakpoint-up(sm) {
        padding-top: 2px;
      }

      @include media-breakpoint-down(sm) {
        padding-top: 4px;
      }
    }

    .btn_link {
      span {
        a {
          color: #fff;
        }
      }
    }
  }
}

.sve-paywall-wrapper {
  position: relative;

  &_container {
    z-index: 2;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 50px);
    margin: 0 20px;

    @include media-breakpoint-down(sm) {
      top: auto;
      bottom: $spacer-base;
      height: auto;
    }
  }

  &_overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: $header-height;
    bottom: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    z-index: 1;
  }
}

.paywall {
  &_container {
    background-color: $white;
    border-radius: 4px;
    max-width: 470px;
    max-height: 80vh;
    overflow: auto;
  }

  &_content {
    @extend %paywall-list-styles;
    margin: 0 $spacer-bg * 2;

    @include media-breakpoint-down(sm) {
      margin: 0 $spacer-bg;
    }
  }

  &_header {
    border-bottom: 1px solid c(neutral-90);
    padding: $spacer-sm 0;
    text-align: center;
    margin-bottom: $spacer-bg;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(sm) {
      font-size: $text-sm;
      font-weight: 600;
      margin-bottom: $spacer-base;
    }

    .paywall_arrow-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 7px;
    }

    > span {
      > svg {
        fill: c(primary-40);
      }

      > .login-link {
        color: c(primary-40) !important;
        margin-left: $spacer-sm;
      }
    }
  }

  &_title {
    margin-bottom: 24px;

    @include media-breakpoint-down(sm) {
      margin-top: $spacer-base;
      font-size: $text-md;
    }
  }

  &_information {
    display: flex;
    align-items: center;
    margin: $spacer-base 0;

    @include media-breakpoint-down(sm) {
      justify-content: center;
    }

    > span {
      margin-left: $spacer-sm;
      color: c(neutral-30);
    }
  }
}

.btn_link {
  display: block;
  text-align: center;
  border-radius: 50px;
  color: $on-primary;
  padding: 6px 12px;
  background-color: c(primary-40);
  width: 100%;
  margin-top: 28px;
  margin-bottom: 26px;
  transition: 0.2s ease;

  @include media-breakpoint-down(sm) {
    margin-top: $spacer-bg;
    margin-bottom: $spacer-base;
  }

  &:hover {
    background: lighten($black, 20%);
    color: $white;
    cursor: pointer;
  }

  > span {
    color: $on-primary;

    a {
      color: #fff;
    }
  }
}

.subscription {
  &_container {
    display: flex;
    justify-content: space-between;
    border: 1px solid c(neutral-80);
    border-radius: 2px;
    padding: $spacer-sm;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 18px;
    }

    &.selected {
      border-color: c(primary-40);
    }
  }

  &_content {
    flex: 1;
  }

  &_box-info {
    position: absolute;
    top: -14px;
    right: $spacer-base;
    background-color: c(neutral-40);
    color: $white;
    padding: $spacer-xs $spacer-md;
    border-radius: 50px;
    text-transform: uppercase;
    font-size: 12px;

    @include media-breakpoint-down(sm) {
      font-size: 10px;
    }
  }

  &_catchline {
    text-transform: uppercase;
    color: c(primary-40);
    font-size: 14px;
    font-weight: 700;

    @include media-breakpoint-down(sm) {
      font-size: 12px;
    }
  }

  &_heading {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  &_reference {
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
    color: c(neutral-50);
    margin: $spacer-sm 0 $spacer-base 0;
  }

  &_paragraph {
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 20px;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  &_item-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: -0.5rem;
    cursor: pointer;
  }
}

input[type='radio']:checked,
input[type='radio']:not(:checked) {
  position: relative;
  appearance: none;
  width: 20px;
  height: 20px;
  align-self: center;
  margin: 0 0 0 $spacer-base;

  &:hover {
    cursor: pointer;
    border-color: c(primary-80);
  }
}

input[type='radio']:checked::before,
input[type='radio']:not(:checked)::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 2px solid c(primary-40);
  border-radius: 100%;
  background: #fff;
}

input[type='radio']:checked::after,
input[type='radio']:not(:checked)::after {
  content: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="%23b32922"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>');
  width: 20px;
  position: absolute;
  top: 0;
  left: 0;

  // not supported on Safari
  //-webkit-transition: all 0.2s ease;
  //transition: all 0.2s ease;
}

input[type='radio']:not(:checked)::after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

input[type='radio']:checked::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
