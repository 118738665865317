.sve-article-tag-container {
  display: inline-flex;
  vertical-align: sub;
  margin-right: 0.5rem;
}

.fp-article-info-image {
  margin-left: calc(-100vw / 2 + (100vw - 368px) / 2);
  margin-right: calc(-100vw / 2 + (100vw - 368px) / 2);
  width: calc(100% + 368px);

  @include media-breakpoint-down(md) {
    margin-left: calc(-100vw / 2 + (100vw - 32px) / 2);
    margin-right: calc(-100vw / 2 + (100vw - 32px) / 2);
    width: calc(100% + 32px);
  }

  picture {
    display: inline-flex;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
  }

  figcaption {
    text-align: start;
  }
}

.sve-article-catchline {
  color: c(primary-40);
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0.1px;

  @media screen and (max-width: 576px) {
    font-size: $text-sm;
  }
}

.sve-article-main-title {
  font-size: 45px;
  line-height: 52px;
  padding-top: 12px;
  @media screen and (max-width: 576px) {
    font-size: 28px;
    line-height: 36px;
    padding-top: 10px;
  }
}

.sve-article-region {
  color: c(neutral-40);
  margin-top: $spacer-sm;

  svg {
    margin-right: 0.5rem;
    display: inline;
  }
}

.sve-article-lead-paragraph {
  font-family: $font-primary;
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  margin: 2rem 0;
}

.sve-article-details {
  border: 1px solid $outline;
  padding: 1rem;
  border-radius: 2px;

  > div {
    gap: 0.5rem;
  }

  span {
    &:first-child {
      margin-right: 0.5rem;
      color: c(neutral-60);
    }

    &:last-child {
      color: c(neutral-40);
    }
  }

  .sve-article-details-separator {
    margin: 1rem 0;
  }

  .fp-authors-container {
    display: flex;
    transition: height 0.2s ease-in-out;

    .fp-article-authors {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: c(neutral-40);
    }

    + div {
      justify-self: end;
      display: flex;
      flex-direction: column;

      button {
        border: 0;
        background: none;

        &:hover,
        &:focus {
          cursor: pointer;
        }
      }
    }
  }
}

.sve-social-media {
  margin: 1rem 0 2rem;
  display: flex;
  align-items: center;
  gap: 8px;

  a {
    display: inline-flex;
  }
}

.sve-date-container {
  display: flex;
  align-items: center;

  @media screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .sve-date-item {
    flex: 0 0 50%;
  }
}

.sve-article-authors {
  display: flex;
  flex-direction: column;
  row-gap: $spacer-md;

  .sve-author-wrapper {
    display: flex;
    align-items: center;
    column-gap: $spacer-xs;
    color: c(neutral-40);

    .sve-author-image {
      align-self: flex-start;
      border-radius: 50px;
      width: 24px;
      min-width: 24px;
      height: 24px;
      overflow: hidden;
      background: #a5b1bb;

      img {
        object-fit: cover;
        max-width: 100%;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.fp-article__subtitle {
  margin-bottom: $spacer-base;
  @include media-breakpoint-down(md) {
    margin-bottom: $spacer-md;
  }
}
