// Product Mixins
@import './breakpoints';
@import '../../design-base-compat/themes/_shared/mixins';

// SVE Mixins
@mixin arrow-right-styles($color) {
  &::after {
    content: url($color);
    display: inline-block;
    width: 18px;
    margin-left: $spacer-sm;

    @include media-breakpoint-down(md) {
      height: 18px;
    }
  }
}
