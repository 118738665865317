// Find Another Solution for creating a article body template
// White space in implemented with temporary solution until we implement standard

//@import '@fp-design/themes/broadsheet/article-body';

//.article-body {
//  margin: 0 0 150px;
//  background-color: blue;
//
//  > * {
//    margin-bottom: 100px;
//  }
//
//  //Current Solution because grid offset is not working
//  .fp-article__subtitle {
//    margin-left: auto;
//    color: purple;
//    max-width: 600px;
//  }
//
//  .fp-article__paragraph {
//    margin-left: auto;
//    max-width: 600px;
//  }
//
//  .fp-article-list {
//    margin-left: auto;
//    max-width: 600px;
//  }
//}

.article_body {
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

.sve-article-lead-paragraph,
.article_body p,
.article_body * {
  word-break: break-word;
}
