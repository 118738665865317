body > #__next {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

//This is helper element for the skyscaper ad
.fp-layout-wrapper {
  position: relative;
  //padding-bottom: 280px;
  margin-bottom: $spacer-xl;
}

.fp-footer {
  max-width: $container-width;
  width: 100%;
  align-self: center;
  margin-top: auto;
}
