.sve-related-articles {
  &_container {
    width: 350px;
    float: right;
    margin: 0 0 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0;

    @media (max-width: 576px) {
      width: 100%;
      float: none;
      margin: 0;
      padding: 0;
      padding-bottom: $spacer-base;
    }

    list {
      flex: 1;
    }

    .sve-teaser_small {
      border-bottom: 1px solid $outline;
    }
  }

  &_title {
    text-transform: uppercase;
    color: c(neutral-40);
    font-size: $text-xs;
    padding-bottom: $spacer-xs;
    border-bottom: 1px solid $outline;
  }
}
