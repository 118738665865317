// Spacing Variables

$spacer-xs: $spacer * 0.25; //4
$spacer-sm: $spacer * 0.5; //8
$spacer-md: $spacer * 0.75; //12
$spacer-base: $spacer; //16
$spacer-bg: $spacer * 1.25; //20
$spacer-lg: $spacer * 1.5; //24
$spacer-xl: $spacer * 2; // 32px
$spacer-xxl: $spacer * 3; //48
