// SVE CTA style
.fp-cta-section {
  margin-left: 4rem;
  margin-right: 4rem;

  padding: 0 $spacer-base;
  @include media-breakpoint-down(md) {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
}

.fp-cta-wrapper {
  background-color: $primary-color;
  border-radius: $spacer-xs;
  color: white;
  padding: $spacer * 2.5;
  @include media-breakpoint-down(sm) {
    padding: 40px $spacer-bg;
  }

  @include media-breakpoint-down(md) {
    border-radius: 0;
  }
}

.fp-cta {
  display: grid;
  gap: var(--fp-gap, 1.5rem);
  grid-template-columns: repeat(var(--fp-columns, 12), 1fr);
  grid-template-rows: repeat(var(--fp-rows, 1), 1fr);
  row-gap: 20px;

  &-title {
    color: white;
    font-size: 24px;
    font-weight: 700;
    line-height: $text-xxl;
    grid-column: 2 / 13;

    @include media-breakpoint-down(sm) {
      font-size: $text-xl;
      line-height: 1.27;
      margin-bottom: 0;
    }
  }

  &-p {
    font-size: $text-md;
    padding-top: $spacer-sm;
    @include media-breakpoint-down(sm) {
      font-size: $text-base;
      padding-top: 0;
    }
  }

  &-link {
    color: white;
    text-decoration: underline;

    a {
      color: white;
      text-decoration: underline;
    }

    &:hover {
      text-decoration: underline;

      a {
        color: white;
        text-decoration: underline;
      }
    }
  }

  &-link,
  &-label {
    @include media-breakpoint-down(sm) {
      font-size: $text-sm;
    }
  }

  &-btn {
    border: 0;
    padding: $spacer-sm $spacer-base;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: $text-base;
    background: $white;
    border-radius: 100px;
    letter-spacing: 0.1px;
    font-weight: 700;
    min-width: 136px;

    &-label {
      color: $primary-color;
    }
  }
}

.fp-cta-label {
  padding-right: $spacer-sm;
}

.fp-cta-p,
.fp-cta-label,
.fp-cta-group {
  grid-column: 2 / 13;
  color: white;
  font-weight: 600;
  @include media-breakpoint-down(sm) {
    grid-column: 1/13;
  }
}
