.sve-topic-list {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: $spacer-md;
  padding-bottom: 0.7rem;

  &_item {
    border: 2px solid c(neutral-80);
    font-size: $text-sm;
    font-family: $font-primary;
    font-weight: bold;
    letter-spacing: 0.5px;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: c(neutral-90);
    }

    @include media-breakpoint-down(sm) {
      font-size: $text-xs;
    }

    a {
      color: #191c1e;
      padding: $spacer-xs $spacer-sm;
      display: block;
    }
  }
}

.sve-topic-list-wrapper {
  max-width: inherit;
  @include media-breakpoint-up(lg) {
    padding: 0;
  }
}
