// Region Header (Navigation)
.fp-region-header {
  margin-bottom: 16px;
}

.fp-region-header-nav {
  display: flex;
  gap: $spacer-sm;
  border-bottom: 1px solid #e1e2e5;
  padding-bottom: 10px;

  &-container {
    cursor: pointer;
  }

  &-list-wrapper {
    position: relative;
  }

  &-list {
    position: absolute;
    z-index: 1;
    top: 1px;
    left: 16px;
    display: flex;
    flex-direction: column;
    padding: 6px 0;
    background: #fff;
    border: 1px solid #c5c6c9;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05),
      2px 12px 25px rgba(32, 33, 38, 0.15);
    border-radius: 4px;
    max-height: 82.5vh;
    overflow: auto;

    @media screen and (max-width: 575px) {
      left: 0;
      top: -3px;
    }
  }
}

.fp-region-header-icon {
  svg {
    height: auto;
    padding-top: 4px;
    @include media-breakpoint-down(lg) {
      width: 16px;
    }
  }
}

.fp-region-header-nav-container {
  transition: all 0.2s cubic-bezier(1, 0, 0, 1);

  &:hover {
    span:not(.fp-region-header_town-label) {
      top: 65%;
    }
  }
}

.fp-region-header_town-label-wrapper {
  position: relative;

  span:not(.fp-region-header_town-label) {
    display: inline-block;
    position: absolute;
    font-size: 0;
    top: 50%;
    margin-left: 5px;
    transform: translate(0, -50%);
    transition: all 0.3s ease-in-out;
  }

  h1 {
    display: inline-block;
  }

  .fp-region-header_town-label {
    font-weight: 700;
    line-height: 40px;
    font-size: 32px;
    @include media-breakpoint-down(lg) {
      font-size: $text-md;
      line-height: 130%;
    }
  }
}

.fp-region-header_region-label {
  font-size: 45px;
  line-height: 44px;
  font-weight: 700;
  min-width: 147px;

  @include media-breakpoint-down(md) {
    font-size: 28px;
    line-height: 36px;
  }
}

.fp-region-header-nav-list-btn {
  display: inline-flex;
  min-width: 300px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border: 0;
  padding: 14px 20px;
  cursor: pointer;
  border-top: 1px solid #e1e2e5;

  &-label {
    font-weight: 700;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    color: #1c1b1f;

    @media screen and (max-width: 575px) {
      font-size: 16px;
    }
  }

  span {
    display: inline-flex;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    span {
      //color: $primary-color;
      text-decoration: underline;

      @media screen and (max-width: 575px) {
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 575px) {
    min-width: calc(100vw - 34px);
  }
}

.fp-region-header-nav-list-btn-letters {
  padding: 0;
  min-width: initial;
  border: 0;
}

.fp-region-header-nav-list-nested {
  padding-left: 14px;
}

.fp-region-header-nav-list li:first-child .fp-region-header-nav-list-btn {
  border-top: 0;
}

.fp-region-header-nav-list-ul {
  li {
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;

    @media screen and (max-width: 575px) {
      padding-bottom: 16px;
    }
  }

  a {
    color: $paragraph-text-color;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;

    @media screen and (max-width: 575px) {
      font-size: 16px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  li:first-child:not(.fp-region-header-nav-list-item-nested) {
    color: $primary-color;
    font-weight: 700;

    a {
      color: $primary-color;
      font-weight: 700;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.fp-region-header-nav-list-ul .fp-region-header-nav-list-item-nested {
  padding: 12px 0 0;
}

.fp-current-region,
.fp-region-header-nav-list-ul .fp-current-region {
  color: $primary-color;
}

.fp-region-header-nav-list-letters {
  cursor: pointer;
}
