// Iva Ilievska Search Page

// FIX ME

.fp-search-form {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  @include media-breakpoint-down(sm) {
    padding-left: 16px;
    padding-right: 16px;
  }

  input {
    max-width: 100%;
    box-shadow: none;
    border: 0;
  }

  .fp-input_search {
    width: 100%;
    background-color: transparent;
    font-size: 22px;
    font-family: $font-primary;
    font-weight: 300;
    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .fp-search-form_container {
    display: flex;
    max-width: 1016px;
    margin: 0 auto;
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid #c5c6c9;
    border-radius: 8px;
  }

  .fp-button-search {
    background-color: transparent;
    border: 0;
  }
}

.fp-search_header {
  display: none;
}

.fp-search-results {
  max-width: 900px;
  margin: 0 auto;
  padding-bottom: 40px;

  li {
    padding: 2rem 1rem;
    border-bottom: 1px solid #e0e0e0;
    @include media-breakpoint-down(sm) {
      padding: 1rem;
    }
  }

  .fp-teaser a {
    display: flex;
    gap: 8px;

    picture img {
      width: 300px;
      aspect-ratio: 16/9;
      height: auto;
      object-fit: cover;
      @include media-breakpoint-down(sm) {
        order: 1;
        aspect-ratio: 1/1;
        width: 100px;
        height: auto;
      }
    }

    .fp-teaser_content {
      order: 2;
    }
  }

  .fp-teaser_excerpt {
    padding-bottom: 0;
    padding-top: 8px;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .fp-search_not_found {
    text-align: center;
  }

  //Teaser changes
  .fp-teaser a {
    gap: 16px;
  }

  .fp-teaser_content {
    padding: 0;
  }

  .fp-teaser_date {
    display: none;
  }

  .fp-teaser_title {
    font-size: 24px;
    line-height: 32px;

    @include media-breakpoint-down(sm) {
      font-size: $text-md;
      line-height: 24px;
    }
  }

  .fp-teaser_catchline {
    display: none;
  }

  .fp-teaser_excerpt {
    font-size: $text-sm;
    margin: 10px 0 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    @include media-breakpoint-down(sm) {
      font-size: 15px;
      margin: 0;
    }
  }
}
