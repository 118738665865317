.fp-embed-code {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  overflow: hidden;
}

.fp-embed-code iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fp-embed-editor {
  padding-bottom: 24px;
}

.fp-free-html {
  margin-bottom: $spacer-bg;
  margin-top: $spacer-bg;
}
