// Embed Component
.fp-free-html {
  align-items: center;
  justify-content: center;
  min-height: 100px;
}

.fp-free-html-container {
  > div,
  .responsiveContainer {
    margin-bottom: 40px;
  }
}

.fp-ombed {
  margin-bottom: 1.5rem;
}
