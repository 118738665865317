.fp-article__blockquote {
  margin: 0 5rem 1.5rem 2.5rem;
  position: relative;

  @media screen and (max-width: 576px) {
    margin: 0 0 1.5rem;
  }
}

blockquote {
  quotes: initial;
  font-style: italic;

  p {
    margin-bottom: 8px;
    font-size: $text-md;

    @media screen and (max-width: 576px) {
      font-size: $text-base;
    }
  }

  &::before {
    content: '“';
    margin-right: 1rem;
    font-size: 44px;
    font-family: $font-secondary;
    position: absolute;
    left: -42px;

    @media screen and (max-width: 576px) {
      font-size: $text-xxl;
      position: inherit;
      margin-right: 0.6875rem;
    }
  }

  &::after {
    content: no-close-quote;
  }

  + small {
    font-family: $font-secondary;
    color: c(neutral-50);
    font-size: $text-md;
    margin-left: 40px;

    @media screen and (max-width: 576px) {
      font-size: $text-base;
    }

    &::before {
      content: '\2014' !important;
      margin-right: 0.5rem;
    }
  }
}
