.sve-btn {
  &_container {
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    color: $on-background;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.1px;
    font-weight: bold;
    padding: $spacer-sm $spacer-xs $spacer-sm 0;
    border-bottom: 1px solid $on-background;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }

    > a {
      color: $on-background;
      column-gap: $spacer-sm;
      display: inline-flex;
      align-items: center;
    }

    &:hover {
      cursor: pointer;
    }

    &.btn-link {
      margin-bottom: 3rem;
    }
  }

  &_link {
    @include media-breakpoint-down(sm) {
      max-width: 95%;
    }

    > a {
      color: $on-background;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
