// This file is a reference point for all the FOOTER
// It is imported in the index.scss file

//fixme IIS
.fp-footer > .fp-grid-container {
  padding: 0;
}

.fp-footer {
  background-color: c(neutral-95);

  > section {
    @include size(100%, 150px);
  }

  &-top {
    padding: 30px $spacer-bg;

    &--logo {
      height: 40px;
      margin-bottom: $spacer-sm;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &--rights {
      font-weight: 600;
      text-align: center;
    }
  }

  .sve-social-media {
    @include flex(center, $g: 0 $spacer-xxl);
    margin: 0;
  }

  &-top--wrapper {
    @include flex(center, center, $spacer-xxl + $spacer-lg);
    margin-bottom: $spacer-base + $spacer-xs;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      gap: $spacer-base;
      margin-bottom: $spacer-base + $spacer-sm;
    }
  }

  &-top--nav {
    @include flex(flex-start, center, $spacer-base, column);
    @include media-breakpoint-down(sm) {
      align-items: flex-start;
      gap: 30px;
    }

    ul {
      @include flex(center, baseline, $wrap: wrap);
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
    }

    li {
      padding: $spacer-xs;
      font-size: $text-sm;

      @include media-breakpoint-down(sm) {
        font-size: $text-md;
        padding: $spacer-sm 0;
      }

      @include media-breakpoint-up(sm) {
        &:not(:first-child):not(:last-child)::after {
          content: '|';
          margin-left: $spacer-sm;
        }
      }

      a {
        color: $black;
        font-weight: 600;
      }

      &:first-child {
        font-size: $text-md;
        font-weight: 700;

        @include media-breakpoint-up(sm) {
          &::after {
            content: ':';
          }
        }
      }
    }
  }
}
