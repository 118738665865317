.fp-header {
  width: 100%;
  z-index: initial;
  background-color: $white;

  // Modifier Fixed Header
  &__fixed {
    // Header elements height should be calculated and added as a padding
    //header-height + navigation height
    //padding-bottom: calc(#{$header-height} + 34px);

    .fp-header {
      &_container {
        background-color: white;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 3;
      }
    }
  }

  &_container {
    @include media-breakpoint-down(lg) {
      padding-left: $spacer-base;
      padding-right: $spacer-base;
    }
  }
  // Top Header Elements
  &__static-links {
    display: flex;
    margin-left: 60px;

    ul {
      align-items: center;
      display: flex;

      li:not(:last-child)::after {
        content: '|';
        margin: $spacer-xs;
        font-size: 0.75rem;
      }

      a {
        display: inline-block;
        color: $heading-text-color;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 0.5px;
        padding: 2px $spacer-sm;

        &:hover {
          background-color: c(neutral-95);
          border-radius: 30px;
          text-decoration: none;
        }
      }
    }
  }

  // Top Header Element

  // Middle Header Part
  &-middle {
    padding-bottom: $spacer-md;
    padding-top: $spacer-sm;

    &-down {
      border-bottom: 1px solid $border-color;
    }

    &_container {
      display: flex;
      justify-content: space-between;
      height: 32px;
    }

    &_left {
      display: flex;
      align-items: center;
      gap: $spacer-xl;
    }

    &_right {
      font-size: $text-xs;
      margin: auto 0 auto auto;
    }

    // Middle Header Elements
    &_left__icons,
    &_right {
      align-items: center;
      display: flex;
      gap: $spacer-base;
    }
  }

  .fp-btn_link {
    @include fp-btn_link(6px, 12px, $secondary-color, $heading-text-color);
    transition: 0.2s ease;

    &:hover,
    &:focus {
      background: lighten($black, 20%);
      color: $white;
    }
  }

  &__login-links {
    font-size: 0.8125rem;
    letter-spacing: 0.5px;
  }

  &__create-account {
    display: flex;
    column-gap: $spacer-sm;
    color: $black;
  }

  &__logout,
  &__create-account,
  .fp-header__static-links,
  .fp-header__search {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  // logo dimensions
  &-logo_wrapper {
    height: $header-logo-height-xl;
    width: auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);

    @include media-breakpoint-down(lg) {
      height: $header-logo-height-lg;
    }
  }

  &-logo {
    display: inline-block;
    height: 100%;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  // Header Navigation

  &-navigation {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: $spacer-sm;
    padding-top: $spacer-sm;
    overflow: hidden;
    overflow-x: auto;
  }

  &-navigation_container {
    @include media-breakpoint-down(lg) {
      padding: 0 $spacer-base;
    }

    ul {
      align-items: center;
      display: flex;
      gap: 20px;

      a {
        font-size: $text-base;
        transition: 0.2s ease;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #191c1e;
        white-space: nowrap;

        &:hover,
        &:focus {
          opacity: 0.8;
        }
      }
    }
  }
}

// Helper Classes
.fp-container-width {
  margin: 0 auto;
  max-width: $container-width;
  width: 100%;
}

.fp-btn-icon {
  cursor: pointer;

  a {
    display: flex;
    align-items: center;
  }
}
// Media screen
.fp-header__login {
  display: none;

  @include media-breakpoint-down(lg) {
    display: block;
  }
}

.fp-header__burger-menu svg {
  pointer-events: none;
}
