.slider {
  scroll-snap-points-x: repeat(715px);
}
.slide {
  color: white;
  //position: relative;
  //overflow: hidden;
}

.slide-content {
  margin: 0 auto;
}

.slide-title {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0.25em;
  line-height: 1.25;
}

.slide-number {
  position: absolute;
  z-index: 10;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  border-radius: 4px;
  top: 20px;
  left: 20px;
}

.slider-fullscreen .slide-number {
  position: fixed;
  top: 20px;
  left: 20px;
  background-color: transparent;
  color: #191c1e;
  @include media-breakpoint-down(md) {
    left: 20px;
  }
}

.slide-text {
  font-size: 1rem;
  line-height: 1.5;
}

.slider-fullscreen {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.slider-action-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1rem;

  button {
    margin: 0 0.5rem;
  }
}

.slider-action-button {
  z-index: 10;
  height: 40px;
  width: 40px;
  font-size: 1.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  transition: ease-in 0.2s;
  border-radius: 50%;
  flex-shrink: 0;

  &:hover {
    box-shadow: 0 0 0 1px #5f7e97;
    cursor: pointer;
    color: #a799ff;
  }

  //svg {
  //  width: 1em;
  //  height: 1em;
  //}

  &:disabled {
    box-shadow: 0 0 0 0 #5f7e97;
    color: rgba(255, 255, 255, 0.15);
    cursor: not-allowed;
  }
}

//.slide-image {
//  //padding: 20px 0;
//  @include media-breakpoint-down(md) {
//    padding: 14px 0 0;
//  }
//
//  &:not(:last-child) {
//    border-bottom: 1px solid #f0f1f4;
//    @include media-breakpoint-down(md) {
//      border-bottom: initial;
//    }
//  }
//
//  img {
//    max-width: 100%;
//    width: 100%;
//    //aspect-ratio: 16/9;
//    object-fit: cover;
//
//  }
//}
//
.right-to-left,
.left-to-right {
  transition: all 1s ease-in-out;
}
.right-to-left-enter {
  transform: translateX(730px);
  z-index: 5;

  @include media-breakpoint-down(lg){
    transform: translateX(75vw);
  }
}

.right-to-left-enter-active {
  transform: translateX(0);
  transition: all 1s ease;
  //z-index: 5;
}

.right-to-left-exit {
  transform: translateX(0);
}

.right-to-left-exit-active {
  transform: translateX(-730px);
  transition: transform 1s ease;

  @include media-breakpoint-down(lg){
    transform: translateX(-75vw);
  }
}

.left-to-right-enter {
  transform: translateX(-730px);
  transition: all 1s ease-in-out;

  @include media-breakpoint-down(lg){
    transform: translateX(-75vw);
  }
}

.left-to-right-exit {
  transform: translateX(730px);
  transition: transform 1s ease;

  @include media-breakpoint-down(lg){
    transform: translateX(75vw);
  }
}

.left-to-right-enter-active {
  transform: translateX(0);
  transition: all 1s ease-in-out;
}

.left-to-right-exit-active {
  transform: translateX(730px);
  transition: transform 1s ease;

  @include media-breakpoint-down(lg){
    transform: translateX(75vw);
  }
}

.right-to-left-enter-done {
  transform: translateX(0);
  transition: all 1s ease-in-out;
}

.slider-fullscreen {
  background-color: white;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
}

.slider-fullscreen .gallery-image-previous,
.slider-fullscreen .gallery-image-next {
  display: none;
}

//buttons
.slider-action-button {
  position: absolute;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.7);
  border: 0;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: none;
    border: 0;
  }
}

.slider-fullscreen .slider-action-button {
  position: fixed;
}

.slider-action-button_next,
.slider-action-button_prev {
  top: calc(50% - 10px);
  transform: translateY(-50%);
  bottom: initial;

  &:hover {
    background-color: rgb(0, 0, 0);
  }
}

.slider-action-button_next {
  right: 10px;
  left: initial;
}

.slider-action-button_prev {
  left: 10px;
  right: initial;
}

.slider-action-button_fullscreen {
  display: none;
  background-color: transparent;
}

.slider-fullscreen .slider-action-button_fullscreen {
  display: flex;
  top: 15px;
  right: 10px;
}

.slider.slide:not(.slider-fullscreen) > div:not(.slide-number) {
  display: flex;
  z-index: 0;
  align-items: center;
  max-width: 960px;
  height: 100%;
}

//div:not(.slider-fullscreen) .slider.slide {
//  margin-left: calc(-100vw / 2 + (100vw - 160px) / 2);
//  margin-right: calc(-100vw / 2 + (100vw - 160px) / 2);
//  width: calc(100% + 160px);
//  background-color: white;
//  height: 100%;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  z-index: 0;
//  @media (max-width: 992px) {
//    margin-left: calc(-100vw / 2 + (100vw - 32px) / 2);
//    margin-right: calc(-100vw / 2 + (100vw - 32px) / 2);
//    width: calc(100% + 32px);
//  }
//}

.slider.slide-content {
  width: 100%;
  background-color: white;
  height: 100%;
  min-width: 100%;
}

.slider-fullscreen .slider.slide-content {
  max-height: calc(100vh - 180px);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fp-image-wrapper {
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
}

.slide-image {
  max-width: 100%;
  width: auto;
  height: 100%;
  object-fit: contain;
}

html {
  scroll-behavior: smooth;
}

.slider {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

@keyframes tonext {
  75% {
    left: 0;
  }
  95% {
    left: 100%;
  }
  98% {
    left: 100%;
  }
  99% {
    left: 0;
  }
}

@keyframes tostart {
  75% {
    left: 0;
  }
  95% {
    left: -300%;
  }
  98% {
    left: -300%;
  }
  99% {
    left: 0;
  }
}

@keyframes snap {
  96% {
    scroll-snap-align: center;
  }
  97% {
    scroll-snap-align: none;
  }
  99% {
    scroll-snap-align: none;
  }
  100% {
    scroll-snap-align: center;
  }
}


@media (hover: hover) {
  .fp-image-wrapper {
    animation-name: tonext, snap;
    animation-timing-function: ease;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  .slider:last-child .fp-image-wrapper {
    animation-name: tostart, snap;
  }
}

//
//.slide-content .fp-image-wrapper {
//  width: 100%;
//  overflow: hidden;
//  height: 100%;
//  max-height: 532px;
//  min-height: 200px;
//  @include media-breakpoint-down(md) {
//    max-height: 300px;
//
//    > img {
//      max-width: 100%;
//      width: auto;
//      object-fit: initial;
//      height: 100%;
//    }
//  }
//}

.slider-fullscreen .fp-image-wrapper {
  height: initial;
}

.slider-action-button_reset {
  display: none;
}

.slider .fp-gallery-image-info {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #8f9194;
  padding: 10px 1rem;
  background-color: white;
  max-width: calc(632px + 2rem);
  margin: 0 auto;
}

.slider-action-button:disabled {
  opacity: 0.4;
}

@media screen and (max-device-width: 991px) and (orientation: landscape){

  .slider-fullscreen .slider-action-button_fullscreen,
  .slider-fullscreen .slide-number {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
  }

  .slider-action-button svg path {
    fill: white;
  }

  .slider-fullscreen .slider.slide-content {
    max-height: inherit;
  }

  .slider-fullscreen .fp-image-wrapper {
    height: initial;
  }
}
