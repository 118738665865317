.fp-image_wrapper {
  height: auto;
  margin: 0;
  margin-bottom: 24px;
  max-width: 100%;
  width: auto;

  img {
    max-width: 100%;
    width: 100%;
  }
}

.fp-article-teaser-image {
  width: 100%;
}
