// Grid System
//

// Prefix for :root CSS variables
$variable-prefix: fp- !default;

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xl2: 1260px,
  xxl: 1400px,
) !default;
// scss-docs-end grid-breakpoints

@include assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include assert-starts-at-zero($grid-breakpoints, '$grid-breakpoints');

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
) !default;
// scss-docs-end container-max-widths

@include assert-ascending($container-max-widths, '$container-max-widths');

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 1.5rem !default;
$grid-row-columns: 6 !default;

$gutters: $spacers !default;

// Container padding

$container-padding-x: $grid-gutter-width * 0.5 !default;

@mixin make-cssgrid($columns: $grid-columns, $breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      @if $columns > 0 {
        @for $i from 1 through $columns {
          .g-col#{$infix}-#{$i} {
            grid-column: auto / span $i;
          }
        }

        // Start with `1` because `0` is and invalid value.
        // Ends with `$columns - 1` because offsetting by the width of an entire row isn't possible.
        @for $i from 1 through ($columns - 1) {
          .g-start#{$infix}-#{$i} {
            grid-column-start: $i;
          }
        }
      }
    }
  }
}

.grid {
  @include make-cssgrid();
  display: grid;
  gap: var(--#{$variable-prefix}gap, #{$grid-gutter-width});
  grid-template-columns: repeat(
    var(--#{$variable-prefix}columns, #{$grid-columns}),
    1fr
  );
  grid-template-rows: repeat(var(--#{$variable-prefix}rows, 1), 1fr);
}
