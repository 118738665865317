// Style for sidebar
//please don't remove the comments

.fp-side-menu {
  @include size(100%, 100%, $maxw: $side-menu-width);
  background: $white;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  left: -10px;
  z-index: -1;
  border: 1px solid $outline;
  box-shadow: $box-shadow-sidemenu;
  //  transform: translateX(-102%);
  transition-timing-function: ease-out;
  transform: translateX(calc((100vw - 1000px) / 2));
  opacity: 0;
  //  transition: opacity 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6) 0s;
  transition: opacity 0.3s cubic-bezier(0.16, 1, 0.3, 1) 0s;

  @include media-breakpoint-down(lg) {
    transform: none;
    left: 0;
  }
  @include media-breakpoint-down(sm) {
    max-width: 100%;
    transform: none;
  }

  &.open {
    z-index: 4;
    opacity: 1;
    //    transform: translateX(calc((100vw - 1000px) / 2));
  }

  &-container {
    @include size(100%, 100%);
    padding: $spacer-bg;
    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }
  }
}

// Background Blur overlay over main content
.fp-side-menu.open + main::before {
  width: 100%;
  height: 100%;
}

main::before {
  backdrop-filter: blur(1.5px);
  background-color: #ffffff36;
  content: '';
  left: 0;
  position: fixed;
  top: $header-height;
  height: 0;
  width: 0;
  z-index: 3;
  @include media-breakpoint-down(lg) {
    top: $header-height-sm;
  }
}

// Modifier Under Header
.fp-side-menu {
  &__down {
    height: calc(100% - #{$header-height});
    top: $header-height;
    @include media-breakpoint-down(lg) {
      height: calc(100% - #{$header-height-sm});
      top: $header-height-sm;
    }
    @include media-breakpoint-down(sm) {
      height: 100%;
      top: 0;
    }
  }

  &-header-mb {
    display: none;
    height: 48px;
    margin-left: -$spacer-bg;
    margin-right: -$spacer-bg;
    border-bottom: 1px solid $border-color;

    @include media-breakpoint-down(sm) {
      display: flex;
      align-items: center;
      justify-content: right;
      padding: 0 $spacer-bg;
      margin-bottom: $spacer-bg;
    }
  }
}

// Helper Classes

.fp-side-menu-group {
  padding-bottom: $spacer-bg;
  padding-top: $spacer-bg;
  border-bottom: 1px solid $border-color;
}
//*** Side-menu Elements

// Search Form
.fp-side-search-form {
  display: block;
  padding-top: 0;

  &-container {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;

    button {
      height: 100%;
      border: 0;
      position: absolute;
      top: 0;
      right: 10px;
      background: transparent;
    }
  }

  &-field {
    width: 100%;
    padding: $spacer-sm $spacer-base;
    border: 1px solid $border-color;
    border-radius: $spacer-xs;

    &:focus-visible {
      outline-color: $outline-color;
    }
  }
}

//Side menu Buttons

.fp-side-menu-btn {
  &-item a {
    width: 100%;
    display: flex;
    align-items: center;
    gap: $spacer-md;
  }

  &-icon {
    display: flex;
  }

  &-subscribe {
    .fp-side-menu-btn-text {
      color: $primary-color;
    }
  }
}

.fp-side-menu-navigation {
  &-list-item-container > a {
    color: $body-text;

    &:hover {
      text-decoration: underline;
    }
  }

  &-list-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.fp-side-menu-navigation-main
  > .fp-side-menu-navigation-list-item
  > .fp-side-menu-navigation-list-item-container
  > a {
  font-weight: 700;
  display: inline-block;
}

.fp-side-menu-navigation-subcategory .fp-side-menu-navigation-list-item {
  margin-bottom: $spacer-bg;
}

.fp-side-menu-navigation .fp-side-menu-navigation-subcategory {
  margin-left: $spacer-sm;
}

.fp-side-menu-navigation-main
  > .fp-side-menu-navigation-list-item
  > .fp-side-menu-navigation-subcategory {
  margin-left: 0;
}

.fp-side-menu-navigation-main > .fp-side-menu-navigation-list-item {
  display: flex;
  flex-direction: column;
}

.fp-side-menu-navigation-main
  > .fp-side-menu-navigation-list-item:not(:last-child) {
  border-bottom: 1px solid $border-color;
}

.fp-side-menu-navigation-main
  > .fp-side-menu-navigation-list-item
  > .fp-side-menu-navigation-list-item-container {
  margin: calc(#{$spacer-bg} / 2) 0;
  padding: calc(#{$spacer-bg} / 2) 0;
}

.fp-side-menu-navigation-list-icon {
  display: inline-flex;
}

.fp-side-menu-navigation-list-item-container:has(
    .fp-side-menu-navigation-list-icon
  ) {
  cursor: pointer;
  margin-bottom: $spacer-bg;
}

@supports not selector(:has(.fp-side-menu-navigation-list-icon)) {
  .fp-side-menu-navigation-main
    > .fp-side-menu-navigation-list-item:not(:nth-child(2))
    > .fp-side-menu-navigation-subcategory
    > .fp-side-menu-navigation-list-item
    > .fp-side-menu-navigation-list-item-container {
    cursor: pointer;
    margin-bottom: $spacer-bg;
  }
}

.fp-side-menu-navigation-main
  > .fp-side-menu-navigation-list-item:nth-child(2)
  .fp-side-menu-navigation-subcategory {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: $spacer-bg;

  .fp-side-menu-navigation-list-item {
    margin: 0;
    padding: 6px 12px;
    border: 1px solid $border-color;
    border-radius: 300px;
    height: 32px;
  }

  .fp-side-menu-navigation-list-label {
    text-decoration: none;
  }
}
