body > div {
  //display: flex;
  //flex-direction: column;
  //height: 100%;
  //width: 100%;
}

footer {
  margin-top: auto;
}

main {
  margin: 0 auto;
  max-width: $container-width;
  width: 100%;
  background-color: white;
}


// Default Styling
.fp-section {
  margin-bottom: 16px;
  padding-bottom: 16px;
}
@media screen and (max-width: calc($container-width + 16px)) {
  .fp-section {
    padding-left: 16px;
    padding-right: 16px;
  }

  .fp-section-no-bottom{
    padding-left: 16px;
    padding-right: 16px;
  }
}
