//Sve Popular Group Style

.sve-popular-list-wrapper {
  display: block;
}

.sve-popular-list {
  display: block;
  height: 100%;
}

.sve-popular-list {
  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px;
    background: #f0f1f4;
    border-radius: 4px;
    font-weight: bold;
    @include media-breakpoint-down(md) {
      font-size: $text-base;
      padding: 8px;
    }

    p {
      margin-bottom: 0;
      font-family: 'Ubuntu',sans-serif;
      font-size: 1rem;
      font-weight: bold;
      line-height: normal;
    }
  }
}

.sve-icon {
  display: flex;
}

.sve-popular-articles {
  .fp-section-header {
    h2 {
      display: inline;
      background-color: white;
      padding-right: 16px;
    }

    &::after {
      top: 50%;
    }
  }
}


.sve-popular-list {
  .sve-teaser_small {
    @include media-breakpoint-down(sm) {
      padding: $spacer-sm 0;
      border: 0;

      &:first-child {
        padding-top: $spacer-base;
      }

      .sve-teaser_image {
        display: none;
      }
    }

    .sve-teaser_image,
    .sve-teaser_content {
      grid-column: auto/span 12;
      width: 100%;
      justify-self: end;
    }

    .sve-teaser_title {
      font-size: $text-md;
      margin-bottom: $spacer-xs;
    }
  }
}
