.sve-contributor {
  padding: $spacer-lg $spacer-base;
  margin-left: calc(-50vw + (100vw - 200px) / 2);
  margin-right: calc(-50vw + (100vw - 200px) / 2);
  width: calc(100% + 200px);

  @include media-breakpoint-down(lg) {
    margin-left: calc(-50vw + (100vw - 32px) / 2);
    margin-right: calc(-50vw + (100vw - 32px) / 2);
    width: calc(100% + 32px);
  }

  img {
    max-width: 100%;
    padding: $spacer-lg 0 $spacer-sm 0;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }

  &_title {
    font-size: $text-md;

    @include media-breakpoint-down(sm) {
      font-size: $text-sm;
    }
  }

  &_phone,
  &_email,
  &_link {
    margin-bottom: 0;
    color: #22abb2;
  }

  &_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &_container > * {
    max-width: 50%;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }

  &_text_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem 1rem 0.5rem 1rem;

    @include media-breakpoint-down(sm) {
      padding-left: 0px;
    }
  }

  span,
  p {
    font-family: $font-primary;
  }
}

.sve-contributor-image {
  max-width: 50%;
}
