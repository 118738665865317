.fp-section-header {
  position: relative;
  z-index: 0;

  h3 {
    padding-top: 5px;
    font-family: $font-primary;
  }

  &.fp-section {
    padding-bottom: 0;
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    background: #191c1e;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
  }
}
