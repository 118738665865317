// Advertisement
// GENERAL STYLE FOR EVERY PLACEHOLDER

// FIX ME
// this is temporary solution for taboola placement, where we add css style for css classes provided by taboola
.taboola-widget.fp-grid-row {
  max-width: 1000px;
  margin: 0 auto;
  @media screen and (max-width: 1000px) {
    padding: 0 16px;
  }
}

.outbrain-widget.fp-grid-row {
  max-width: 1000px;
  margin: 0 auto;
  @media screen and (max-width: 1000px) {
    padding: 0 16px;
  }
}

.fp-ad-placeholder {
  background-color: transparent;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-word;
  position: relative;

  p {
    color: black;
    font-size: 14px;
  }

  &::before {
    content: 'Anzeige';
    position: absolute;
    top: -18px;
    display: block;
    left: 0;
    text-transform: uppercase;
    font-weight: 300;
    color: #888b8d;
    font-size: 12px;
    white-space: nowrap;
  }
}

.placeholder-skyscraper {
  width: 300px;
  max-width: 300px;
  height: 600px;
  top: 70px;
  right: calc(((100vw - $container-width) / 2) - 320px);
  // Responsive solution
  @media screen and (max-width: 1620px) {
    display: none;
  }
}
// if page empty the skyscraper as is creating bug scroll
.fp-layout-wrapper {
  min-height: 400px;
  @media screen and (max-width: 1620px) {
    min-height: initial;
  }
}

.placeholder-fireplace {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 107px;
  height: calc(100vh - 107px);
  z-index: -2;
  // Responsive solution
  @include media-breakpoint-down(xl) {
    display: none;
  }
}

// LOOK -> leaderboard

.leaderboard {
  @include media-breakpoint-down(xl2) {
    display: none;
  }
}

.placeholder-leaderboard {
  width: 970px;
  max-width: 970px;
  height: 250px;
  margin: 16px auto 60px;
  // Responsive solution
  @include media-breakpoint-down(xl2) {
    display: none;
    width: 320px;
    max-width: 320px;
    height: 100px;
  }
}
//Visible only in mural not in email
.placeholder-leaderboard_small {
  width: 728px;
  max-width: 728px;
  height: 90px;
  margin: 16px auto 60px;
  // Responsive solution
  @include media-breakpoint-down(lg) {
    display: none;
    width: 320px;
    max-width: 320px;
    height: 100px;
  }
}

// LOOK -> rectangle

.placeholder-rectangle {
  width: 300px;
  max-width: 300px;
  height: 600px;
  margin: 16px auto 60px;
  display: none;
  // Responsive solution
  @include media-breakpoint-down(md) {
    display: block;
    width: 300px;
    max-width: 300px;
    height: 250px;
  }
}

.placeholder-rectangle-inline {
  display: none;
  width: 600px;
  max-width: 600px;
  height: 250px;
  margin: 16px auto 60px;
  // Responsive solution
  @include media-breakpoint-down(lg) {
    display: block;
    width: 300px;
    max-width: 300px;
    height: 250px;

    &.placeholder-rectangle-inline-large {
      height: 600px;
    }
  }
}

.article_body .placeholder-rectangle-inline {
  margin: 50px auto 40px;
}

.fp-ad-placeholder.placeholder-article-inline {
  width: 600px;
  max-width: 600px;
  height: 300px;
  margin: 50px auto 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0;
  @include media-breakpoint-down(md) {
    float: none;
    width: 300px;
    height: 250px;
  }
}

.fp-ad-placeholder.placeholder-rectangle-article-inline {
  width: 640px;
  max-width: 640px;
  height: 250px;
  margin: 50px auto 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0;
  @include media-breakpoint-down(md) {
    width: 300px;
    max-width: 300px;
    height: 250px;

    &.placeholder-rectangle-article-inline-large {
      height: 600px;
    }
  }
}

.fp-position_fixed {
  position: fixed;
}

.fp-position_absolute {
  position: absolute;
}

.fp-header + .fp-ad-placeholder {
  margin: 32px auto;
}

.fp-region-header + .fp-ad-placeholder {
  margin: 32px auto;
}

.fp-article-group-small-main .fp-ad-placeholder {
  margin-top: 48px;
  margin-bottom: 32px;
}

.placeholder-rectangle-inline {
  margin-top: 32px;
  margin-bottom: 32px;
}
