article {

  @media (max-width: 992px) {
    padding: 0 1rem;
  }

  &.fp-grid-row {
    grid-row-gap: 0;
  }
}

.fp-article_key-visual-details {
  .fp-article_key-visual-desc-field {
    padding: 0;
    text-align: left;
  }
}

//.article_header
//  > *:not(
//    .fp-advertisement,
//    .sve-related-articles_container,
//    .sve-contributor
//  ),
//.article_body
//  > *:not(
//    .fp-advertisement,
//    .sve-related-articles_container,
//    .sve-contributor
//  ) {
//  max-width: 1000px;
//  margin-left: auto;d
//  margin-right: auto;
//}

.article_body > .fp-article__blockquote {
  max-width: 467px;
  position: relative;
}

.article_body > .fp-image_wrapper {
  padding-left: 0;
  padding-right: 0;
}

.article_body {
  max-width: 685px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.use-alternative-container {
  .fp-article-info-image {
    display: none;
  }
}

.alternative-container {
  display: none;
}

.use-alternative-container {
  .alternative-container {
    display: block;
  }
}
