@import './breakpoints';

// Base Mixins
@mixin size($w, $h, $maxw: null, $maxh: null, $minw: null, $minh: null) {
  height: $h;
  max-height: $maxh;
  max-width: $maxw;
  min-height: $minh;
  min-width: $minw;
  width: $w;
}

// Flexbox Mixins
@mixin flex($x: null, $y: null, $g: null, $dir: null, $wrap: null) {
  align-items: $y;
  display: flex;
  flex-direction: $dir;
  flex-wrap: $wrap;
  gap: $g;
  justify-content: $x;
}
// Grid Mixins
@mixin grid-container($w, $maxw, $margin) {
  margin: $margin;
  max-width: $maxw;
  width: $w;
}

@mixin grid-row($cg, $rg, $r) {
  display: grid;
  grid-column-gap: $cg;
  grid-row-gap: $rg;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: $r;
}

@mixin grid-column($c, $r, $s: null, $e: null) {
  grid-column: $c;
  grid-column-end: $e;
  grid-column-start: $s;
  grid-row: $r;
}

// Mixin for padding
@mixin padding($padding...) {
  padding: $padding;
}

// Mixin for margins
@mixin margin($margin...) {
  margin: $margin;
}

@mixin fp-btn-link($pv, $ph, $bc, $tc) {
  background-color: $bc;
  color: $tc;
  padding: $pv $ph;
}

.hc-position-center {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
