.fp-free-html-container {
  display: block;
}

.fp-article-list,
.fp-free-html-container {
  margin: 0 auto;

  ul,
  ol {
    list-style: disc;
    list-style-position: inside;
    padding: 0.75rem;
    margin-left: 0.75rem;

    li {
      display: list-item;
      font-family: $font-secondary;
      font-size: $text-md;
      line-height: 1.5;
      list-style-position: outside;
      margin-bottom: 16px;

      &::before {
        display: none;
      }

      &::marker {
        color: $on-background;
      }

      @media screen and (max-width: 576px) {
        font-size: $text-base;
      }
    }
  }

  ol {
    list-style: decimal;
    margin-left: 1rem;
  }

  &.lower-latin ul {
    list-style: lower-latin;
    list-style-position: inside;

    li {
      list-style-image: none;
      padding: 8px;

      &::before {
        display: none;
      }
    }
  }

  &.lower-roman ul {
    list-style: lower-roman;
    list-style-position: inside;

    li {
      list-style-image: none;

      padding: 8px;

      &::before {
        display: none;
      }
    }
  }

  &.decimal ul {
    list-style: decimal;
    list-style-position: inside;

    li {
      list-style-image: none;

      padding: 8px;

      &::before {
        display: none;
      }
    }
  }
}
