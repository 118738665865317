// Sve Region Links

.sve-region-links {
  ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 7px;
    row-gap: 7px;

    li {
      a {
        color: #191c1e;
        padding-right: 7px;
        font-weight: 600;
        font-size: $text-md;
      }

      &:not(:last-child) a {
        border-right: 2px solid black;
      }
    }
  }

  h3 {
    font-weight: 700;
    color: #191c1e;
    font-size: $text-md;
    border-top: 1px solid #e1e2e5;
    padding-top: 20px;
  }

  &.doc-component {
    background-color: c(neutral-95);
    margin-bottom: 20px;
    padding-bottom: 16px;

    .sve-region-links-editor-placeholder {
      font-family: $font-primary;
      text-align: center;
      padding: 16px 0 0;
      margin: 0 auto;
      border: 0;
    }
  }
}
